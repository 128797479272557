/* The switch - the box around the slider */
.custom-switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 35px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .custom-slider {
    position: absolute;
    cursor: pointer;
    width: 60px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    border: 1px solid #ddd;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
    margin: 0 10px;
    &:before {
      position: absolute;
      color: white;
      font-family: primeicons;
      content: "\e9ab";
      height: 28px;
      width: 28px;
      left: 4px;
      bottom: 2.5px;
      border-radius: 5px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
  input + .custom-slider:before {
    background-color: #0275b3;
  }
  
  input:checked + .custom-slider::before {
    content: "\e973";
    background-color: #ff9900;
  }
  
  input:checked + .custom-slider:before {
    transform: translateX(22px);
  }
}