:root {
  font-family: Inter var, sans-serif;
}

td {
  user-select: text !important;
  -webkit-user-drag: none;
}

html {
  scroll-behavior: smooth;
}

h1 {
  color: #0a1c29;
}

body {
  overflow: auto;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#beamerSelector {
  display: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  margin: 0 25px;
}

.p-float-label input:focus ~ label,
.p-float-label input:-webkit-autofill ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  top: 0.1rem !important;
  background: white;
  padding: 0 5px;
}

.p-progressbar.p-progressbar-indeterminate {
  height: 5px;
  border-radius: 0px;
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 10;
}

.p-paginator {
  justify-content: end;
}

.p-datatable {
  .p-datatable-tbody {
    > tr {
      &:hover {
        background-color: #e7e7e7;
      }
      > td {
        padding: 5px;
        button {
          padding: 5px;
        }
        .p-dropdown-label {
          padding: 5px;
        }
      }
    }
  }
  .p-datatable-thead > tr > th {
    padding: 5px;
  }
  .p-datatable-header {
    padding: 5px;
  }
}

.p-float-label input ~ label,
.p-float-label input:-webkit-autofill ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper ~ label,
.editor > label {
  color: #495057;
  // top: 0px !important;
  background: white;
  padding: 0 5px;
  font-size: 16px !important;
  // font-weight: bold;
}

.editor > label {
  padding-left: 15px;
}

.p-datatable-thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.new-table {
  font-family: monospace;
}

.p-button {
  &.p-button-text {
    color: var(--main-color);
  }
}

.disable-events {
  pointer-events: none;
}