.view-control {
  display: flex;
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 5px;
  height: 50px;
  > span {
    padding: 10px 0;
    width: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:nth-child(1) {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:nth-child(3) {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    &.active {
      background-color: var(--main-color);
      color: white;
    }
  }
}

.p-tabview .p-tabview-nav li:not(.p-highlight) .p-tabview-title {
  font-weight: 400;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  font-weight: 700;
  color: var(--main-color);
  border-color: var(--main-color);
}
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: var(--main-color);
}

.selector-card {
  flex: 1;
  width: 100%;
  overflow: hidden;
  height: 100%;
  .selector--header {
    display: none;
    border-bottom: 1px solid #dee2e6;
    height: 35px;
    justify-content: space-between;
    align-items: center;
    > .header--list {
      height: 100%;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: default;
      &:nth-child(1) {
        > .header--item {
          padding-left: 15px;
        }
      }
      &:nth-child(2) {
        margin-left: -28px;
        > .header--item {
          padding-left: 28px;
        }
      }
      &:nth-child(3) {
        margin-left: -28px;
        > .header--item {
          padding-left: 28px;
          border-top-right-radius: 5px;
        }
      }
      > .header--item {
        font-weight: bold;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        border-top-left-radius: 5px;
        .header--item-selected {
          display: flex;
          align-items: center;
          color: #6f6f6f;
          > i {
            color: var(--main-color);
          }
        }
      }
      &.active {
        > .header--item {
          background-color: var(--main-color);
          color: white;
        }
        > i.arrow {
          background-color: var(--main-color);
          border-color: var(--main-color);
        }
        > i.pi-angle-double-right {
          color: white;
        }
      }
    }
    .arrow {
      border: solid #dee2e6;
      border-width: 0 3px 3px 0;
      display: block;
      padding: 3px;
      margin-right: 15px;
      margin-left: -12px;
      width: 26px;
      height: 26px;
      float: right;
      background-color: white;
      border-radius: 3px;
    }

    .right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    .pi-angle-double-right {
      margin-right: 15px;
      margin-left: -32px;
      color: #a2a2a2;
    }
  }
  .selector--body {
    height: 100%;
    overflow-y: auto;
    .p-tabview-panels {
      padding: .5rem 0;
    }
    .content {
      height: 100%;
      padding: 10px 24px;
      .indicador-acao {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .p-button {
          margin-right: 15px;
          padding: 5px;
          background-color: var(--sec-color);
          color: black;
        }
      }
      .configs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        > div:not(.view-control) {
          display: flex;
          align-items: center;
          flex-grow: 1;
          > span {
            margin-right: 10px;
            display: flex;
            align-items: center;
            &:nth-child(1) {
              width: max(50%, 300px);
            }
            label {
              margin-left: 5px;
            }
            input {
              width: 100%;
            }
          }
        }
        .p-checkbox.p-highlight .p-checkbox-box {
          border-color: var(--main-color);
          background: var(--main-color);
        }
      }
      .items {
        .setor {
          .setor--header {
            position: sticky;
            top: 0;
            z-index: 11;
            background-color: var(--main-color);
            color: white;
            border-radius: 5px;
            padding: 5px;
            margin-bottom: 10px;
            h2,
            h3 {
              margin: 0;
            }
            .p-skeleton {
              margin: 5px;
              width: calc(100% - 10px) !important;
            }
          }
          .setor--body {
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;
            .item--card {
              width: 171px;
              height: 194px;
              cursor: pointer;
              display: flex;
              flex-direction: column;
              border: 1px solid #ccc;
              border-radius: 5px;
              padding: 10px;
              margin-right: 15px;
              margin-bottom: 10px;
              box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024,
                0 1px 3px #0000001f;
              > img {
                width: 149px;
                height: 134px;
                margin-top: 5px;
              }
              .demo-banner {
                display: none;
              }
              &.demo {
                .reports__grid__section__content--demo-banner-rect {
                  fill: var(--other-color);
                  transform: translate(89px, -17px) rotate(45deg);
                  filter: drop-shadow(-3px -4px 4px #a3a3a3);
                }
                .reports__grid__section__content--demo-banner-text {
                  transform: rotate(45deg);
                  z-index: 2;
                  color: white;
                  font-size: 0.6rem !important;
                  font-weight: bold;
                  margin-top: 22px;
                  margin-left: 94px;
                }
                .reports__grid__section__content--demo-banner-svg {
                  z-index: 1;
                  margin-top: -56px !important;
                  margin-left: -12px !important;
                  margin-bottom: -182px;
                }
              }
              &.disabled {
                box-shadow: none;
                cursor: initial;
                border-color: #e8e8e8;
                opacity: .7;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
              }
              > .p-skeleton {
                &:nth-child(1) {
                  margin-bottom: 10px;
                }
              }
              .item--header {
                font-size: 14px;
                padding-bottom: 5px;
                font-weight: 700;
                color: #495057;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
      .tabs-report {
        height: calc(100% - 87px);
        .p-tabview-panels {
          padding-top: 0;
          height: calc(100% - 58px);
          overflow-y: auto;
          overflow-x: hidden;
        }
      }
    }
  }
}

.container--card {
  width: 171px;
  height: 194px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.container--card .item--header {
  margin-right: 20px;
}

.pi-external-link {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 10;
}
